const Contact = () => {
  return (
    <section className="contact">
      <h3>Contact</h3>
      <div className="card">
        <h4>Jim Snelgrove</h4>
        <ul>
          <li>
            <b>Phone:</b>
           
          </li>
          <li>
            <b>E-mail:</b>
           
          </li>
          <li>
            <b>Address:</b>
            <p>
           
            </p>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Contact
