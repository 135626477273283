// import { useState } from "react"
import { Routes, Route } from "react-router-dom"
import Layout from "./Components/Layout"
import Home from "./Components/Home"
import About from "./Components/About"
import Contact from "./Components/Contact"
import Blog from "./Components/Blog"
import Projects from "./Components/Projects"
import Missing from "./Components/Missing"
import Login from "./Components/Login"

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
