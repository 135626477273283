import { Link } from "react-router-dom"

const Nav = ({ page, handleClick }) => {
  return (
    <nav>
      <Link to="/">
        <button className="home-btn btn">Home</button>
      </Link>
      <Link to="/about">
        <button className="about-btn btn">About</button>
      </Link>
      <Link to="/contact">
        <button className="contact-btn btn">Contact</button>
      </Link>
      <Link to="/projects">
        <button className="projects-btn btn">Projects</button>
      </Link>
      <Link to="/blog">
        <button className="blog-btn btn">Blog</button>
      </Link>
      <Link to="/login">
        <button className="blog-btn btn">Login</button>
      </Link>
    </nav>
  )
}

export default Nav
