import { Link } from "react-router-dom"

const Missing = () => {
  return (
    <main className="Missing">
      <h2>Page Not Found</h2>
      <p>Well fuck, nothing there</p>
      <p>
        <Link to="/">Vist Our Homepage </Link>
      </p>
    </main>
  )
}

export default Missing
