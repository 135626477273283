import React from "react"

const ServiceCard = ({ title, description, image, alt, color }) => {
  return (
    <div className="service-card" style={{ backgroundColor: color }}>
      <img src={process.env.PUBLIC_URL + image} width="150" alt={alt} />
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  )
}

export default ServiceCard
