import Nav from "./Nav"
const Header = () => {
  return (
    <header className="header">
      <h2>jimsnelgrove.com</h2>
      <Nav />
    </header>
  )
}

export default Header
