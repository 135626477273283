import ServiceCard from "./ServiceCard"

const Home = () => {
  return (
    <main className="Home">
      <h2>Home</h2>
      <p>This site is currently under construction</p>
      <section className="home-section">
        <h3>Freelance Web Development Services</h3>
        <div className="service-cards">
          <ServiceCard
            title="HTML E-mail Templates"
            description="Create custom E-mail templates for professional business communications"
            image="/images/email.png"
            alt="email img"
            color="orangered"
          />
          <ServiceCard
            title="Website Construction"
            description="If you have a design idea for a website, I can write the code and make it work."
            image="/images/home.png"
            alt="test"
            color="purple"
          />
          <ServiceCard
            title="Web-based Tools"
            description="A shared custom calendaring app for small businesses.  Web based customer forms to streamline your information flow.  A calculator app specific to your business.  Contact me to build it"
            image="/images/home.png"
            alt="test"
            color="limegreen"
          />
          <ServiceCard
            title="Marketing Pages"
            description="One page splash ads for your business"
            image="/images/home.png"
            alt="test"
            color="gold"
          />
        </div>
      </section>
    </main>
  )
}

export default Home
